let authToken = sessionStorage.authToken || localStorage.authToken;

// Class ApiError
class ApiError extends Error {
    /**
     * 
     * @param {number} statusCode 
     * @param {string} message 
     */
    constructor(statusCode, message) {
        super(message);
        this.statusCode = statusCode;
        this.message = message;
        this.stack = (new Error()).stack;
    }
}

/**
 * 
 * @param {string} method
 * @param {string} path 
 * @param {boolean} sendAuthToken 
 * @param {object} requestBody 
 * @return {Promise.<object>}
 */
function doRequest(method, path, sendAuthToken, requestBody) {
    let xhr = new XMLHttpRequest();
    xhr.open(method, "/api/" + path, true);

    if (sendAuthToken) {
        xhr.setRequestHeader("Authorization", "Bearer " + authToken);
    }
    
    if (method === "POST" || method === "PUT") {
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(requestBody));
    } else {
        xhr.send();
    }

    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            // token is gone
            if (xhr.status === 401) {
                window.localStorage.removeItem("authToken");
                window.location.replace("/admin/login.html");
            }

            // Server response arrived; it might be a success or error response
            if (xhr.status === 200) {
                // success
                resolve(JSON.parse(xhr.responseText));
            } else {
                // error
                let errorText;

                try {
                    // try to get error message from response body
                    let response = JSON.parse(xhr.responseText);

                    if (response && response.message) {
                        errorText = response.message;
                    } else {
                        // fallback
                        errorText = xhr.statusText;
                    }
                } catch (e) {
                    // fallback
                    errorText = xhr.statusText;
                }

                reject(new ApiError(xhr.status, errorText));
            }
        };

        xhr.onerror = () => {
            // We couldn't even fetch the server response
            reject(new ApiError(xhr.status, xhr.statusText || "Connection error"));
        }
    });
}

/**
 * 
 * @param {string} path 
 * @param {boolean} sendAuthToken 
 * @return {Promise.<object>}
 */
function doGet(path, sendAuthToken) {
    return doRequest("GET", path, sendAuthToken);
}

/**
 * 
 * @param {string} path 
 * @param {boolean} sendAuthToken 
 * @param {object} requestBody 
 * @return {Promise.<object>}
 */
function doPost(path, sendAuthToken, requestBody) {
    return doRequest("POST", path, sendAuthToken, requestBody);
}

/**
 * 
 * @param {string} path 
 * @param {boolean} sendAuthToken 
 * @param {object} requestBody 
 * @return {Promise.<object>}
 */
function doPut(path, sendAuthToken, requestBody) {
    return doRequest("PUT", path, sendAuthToken, requestBody);
}

/**
 * 
 * @param {string} path 
 * @param {boolean} sendAuthToken 
 * @return {Promise.<object>}
 */
function doDelete(path, sendAuthToken) {
    return doRequest("DELETE", path, sendAuthToken);
}

let apiClient = {/**
     * @return {boolean}
     */
    isLoggedIn: function() {
        return authToken != null && authToken != "";
    },


    addNews: function(newsInfo) {        
        return doPost("news", true, newsInfo);
    },

    getNewsHeaders: function() {
        return doGet("news/headers", true);
    },

    getNewsById: function(id) {
        return doGet("news/" + id, true);
    },

    editNews: function(newsInfo) {
        return doPut("news", true, newsInfo);
    },

    deleteNews: function(id) {
        return doDelete("news/" + id, true);
    },

    /**
     * @param {object} photoInfo
     * @return {Promise.<void>}
     */
    addPhoto: function(photoInfo) {
        return doPost("photo", true, photoInfo)
    },

    addCover: function(photoInfo) {
        return doPost("cover", true, photoInfo);
    },
    
    getPhotoList: function() {
        return doGet("photo/list", true);
    },

    getCoverList: function() {
        return doGet("cover/list", true);
    },

    getPhoto: function(filename) {
        return doGet("photo/" + filename, true);
    },

    getCover: function(filename) {
        return doGet("cover/" + filename, true);
    },

    deletePhoto: function(filename) {
        return doDelete("photo/" + filename, true);
    },

    deleteCover: function(filename) {
        return doDelete("cover/" + filename, true);
    },

    getStoryHeaders: function() {
        return doGet("story/headers", true);
    },

    getStory: function(id) {
        return doGet("story/" + id, true);
    },

    addStory: function(storyInfo) {
        return doPost("story", true, storyInfo);
    },

    editStory: function(storyInfo) {
        return doPut("story", true, storyInfo);
    },

    deleteStory: function(id) {
        return doDelete("story/" + id, true);
    },

    commonGetAllNews: function() {
        return doGet("common/news", false);
    },

    /**
     * @return {Array.<object>}
     */
    commonGetCropStories: function() {
        return doGet("common/stories", false);
    },

    commonGetStoryContent: function(id) {
        return doGet("common/" + id, false);
    },


    /**
     * @param {string} email
     * @param {string} password
     * @return {Promise.<object>}
     */
    login: function(email, password) {
        return doPost("session", false, {
            "email": email,
            "password": password
        });
    },
    /**
     * @return {Promise.<void>}
     */
    logout: function() {
        return doDelete("session", true);
    }, 
};

export default apiClient;
