import apiclient from "./apiclient";
import cardTemplate from "./templates/card.html";

document.addEventListener("DOMContentLoaded", () => {
    // card loader
    let cardHolder = document.getElementById("stories");

    // are we at a story link?
    if (window.location.hash.replace(/^ /, "").includes("_")) {
        let storiesLink = document.querySelector("#tab-stories a");
        storiesLink.click();
    }

    apiclient.commonGetCropStories().then(result => {
        document.querySelectorAll(".story-card").forEach(e => e.remove());

        for (let cropStoryInfo of result) {
            let storyCard = addCard(cropStoryInfo, cardHolder);
            addHandlers(storyCard);
        }
    });


    // news loader
    let newsHolder = document.getElementById("news");
    apiclient.commonGetAllNews().then(result => {
        document.querySelectorAll(".news-card").forEach(e => e.remove());
        for (let newsInfo of result) {
            addNewsCard(newsInfo, newsHolder);
        };
    });
});
// news functions

function addNewsCard(newsInfo, newsHolder) {
    let header = newsInfo.header;
    let newsText = newsInfo.content;
    let tmpDate = new Date(newsInfo.date);
    let date = "" + tmpDate.getDate() + "." + (tmpDate.getMonth() + 1) + "." + tmpDate.getFullYear();

    let newsCard = document.createElement('div');
    newsCard.className = "card news-card";

    let newsHeader = document.createElement('h4');
    newsHeader.textContent = header;

    let newsContent = document.createElement('p');
    newsContent.className = "news-text";
    newsContent.textContent = newsText;

    let newsDate = document.createElement('p');
    newsDate.className = "news-date";
    newsDate.textContent = date;

    newsCard.appendChild(newsHeader);
    newsCard.appendChild(newsContent);
    newsCard.appendChild(newsDate);
    newsHolder.appendChild(newsCard);
};



// card functions
/**
 * 
 * @param {object} cropStoryInfo 
 * @param {HTMLDivElement} cardHolder 
 */
function addCard(cropStoryInfo, cardHolder) {
    let id = cropStoryInfo.id;
    let header = cropStoryInfo.header;
    let quotation = cropStoryInfo.quotation;
    let cover = cropStoryInfo.cover;

    let cardHTML = cardTemplate({
        headerUnderscore: header.replace(/ /g, "_"),
        id: id,
        href: "#" + header.replace(/ /g, "_"),
        cover: cover,
        header: header,
        quotation: quotation
    });

    cardHolder.insertAdjacentHTML("beforeend", cardHTML);

    let storyCard = cardHolder.lastElementChild;
    return storyCard;
};
/**
 * 
 * @param {HTMLDivElement} card 
 */
function addHandlers(card) {
    let cardCoverLink = card.querySelector(".card-cover-link");
    let cardCover = card.querySelector(".card-cover");
    let cardHeader = card.querySelector(".card-text");
    let cardQuote = card.querySelector(".card-quote");
    let cardAuthor = card.querySelector(".card-author");
    let cardContent = card.querySelector(".card-content");
    let hideButton = card.querySelector(".hide-button");
    let shareButton = card.querySelector(".share-button");
    let popupBar = card.querySelector(".popup-bar");

    /*
    shareButton.addEventListener("click", event => {
        if (popupBar.classList.contains("popup-bar-visible")) {
            popupBar.classList.remove("popup-bar-visible");
        } else {
            popupBar.classList.add("popup-bar-visible");
            // position popup bar above button
        }
    });
    */

   shareButton.addEventListener("click", event => {
        shareButton.classList.add("popup-bar-visible");
    });

    let closeHandler = event => {
        event.preventDefault();
        window.location.hash = "#stories";

        card.classList.remove("open-card");
        card.classList.add("card");

        cardCover.classList.remove("open-card-cover");
        cardCover.classList.add("card-cover");

        cardHeader.classList.remove("open-card-text");
        cardHeader.classList.add("card-text");

        cardQuote.classList.remove("open-card-quote");
        cardQuote.classList.add("card-quote");
        
        cardAuthor.classList.remove("open-card-author");
        cardAuthor.classList.add("card-author");
        cardAuthor.textContent = "";

        cardContent.classList.remove("open-card-content");
        cardContent.classList.add("card-content");
        cardContent.innerHTML = "";

        hideButton.classList.remove("open-hide-button");
        hideButton.classList.add("hide-button");
        
        shareButton.classList.remove("open-share-button");
        shareButton.classList.remove("popup-bar-visible");
        shareButton.classList.add("share-button");
    };

    hideButton.addEventListener("click", closeHandler);
    
    cardCoverLink.addEventListener("click", event => {
        if (card.classList.contains("open-card")) {
            event.preventDefault();
            closeHandler(event);
            return;
        }

        cardAuthor.textContent = cardHeader.textContent;

        apiclient.commonGetStoryContent(card.getAttribute("data-id")).then(result => {
            cardContent.innerHTML = result.content;
        });


        card.classList.remove("card");
        card.classList.add("open-card");
        // card.style.cssText = "";

        cardCover.classList.remove("card-cover");
        cardCover.classList.add("open-card-cover");

        cardHeader.classList.remove("card-text");
        cardHeader.classList.add("open-card-text");

        cardQuote.classList.remove("card-quote");
        cardQuote.classList.add("open-card-quote");

        cardAuthor.classList.remove("card-author");
        cardAuthor.classList.add("open-card-author");

        cardContent.classList.remove("card-content");
        cardContent.classList.add("open-card-content");

        hideButton.classList.remove("hide-button");
        hideButton.classList.add("open-hide-button");

        shareButton.classList.remove("share-button");
        shareButton.classList.add("open-share-button");
    });

    let linkedStory = decodeURIComponent(window.location.hash).replace(/^#/, "").replace(/_/g, " ");

    if (linkedStory === cardHeader.textContent) {
        cardCoverLink.click();
    }
};